import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/app/www/theme/src/templates/single-post.js";
import { Link } from 'gatsby';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Les illusions d'optique sont très intéressantes car elles nous en disent beaucoup sur la manière de fonctionner de notre vision et en creux nous alertent des choses à ne pas faire dans une visualisation de données.`}</p>
    <h2>{`Une illusion basée sur un gradient de couleur`}</h2>
    <br />
    <p>{`Trois rectangles sont cachés dans cette image. On ne peut pas les voir car ils sont exactement de la même couleur que le fond. Appuyer sur le bouton "Show me" pour les repérer. Essayer de les glisser avec la souris et regarder l'effet. `}</p>
    <p>{`Les options "Uniform color" et "Grey scale" permettent de varier les  situations. `}</p>
    <p>{`A essayer sur une tablette pour la sensation maximale. `}</p>
    <center>
      <iframe width="100%" height="526" frameBorder="0" src="https://observablehq.com/embed/@alainro/perception-illusion-part-i?cell=viewof+uniform&cell=viewof+blackwhite&cell=viewof+show&cell=viewof+display"></iframe>
    </center>
    <h2>{`La vision 3D court-circuite l'oeil`}</h2>
    <center>
Presser/Relacher le bouton de la souris sur l'image pour voir l'effet.
      <iframe width="100%" height="578" frameBorder="0" src="https://observablehq.com/embed/@alainro/perception-illusion-part-ii?cell=image"></iframe>
      <p>{`Presser/Relacher le bouton de la souris sur l'image pour voir l'effet.`}</p>
      <iframe width="100%" height="535" frameBorder="0" src="https://observablehq.com/embed/@alainro/perception-illusion-part-ii?cell=wall"></iframe>
    </center>
    <h2>{`Les apprentissages court-circuitent l'oeil`}</h2>
    <p>{`Connu sous le nom de l'effet `}<a parentName="p" {...{
        "href": "https://fr.wikipedia.org/wiki/Effet_Stroop"
      }}>{`Stroop`}</a>{` (1935), cette illusion n'en est pas une. Mais c'est néanmoins un effet très troublant qui montre que le processus de lecture automatique semble avoir priorité dans le processus cognitif de la vision.`}</p>
    <center>
      <p><strong parentName="p">{`Nommer la couleur dans laquelle est écrit chacun de ces mots le plus rapidement possible`}</strong></p>
    </center>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "42.94478527607362%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAABYlAAAWJQFJUiTwAAABsElEQVQozz1SbXKrMAzk/mdpk4ZSvgNOAji2ZWHyaK6zT3Y6+cHIGq93VyuytvuHYdywroTVB1zMiE5Xcmb4lbFertgOObyx8Mwg51EXvzD3Fcwenhj7XmDbziBakVEjh7MCxcdMGBThuyIBEkhI2U3YzZcQOXjvYaUeugY3PYPlngR3udTQ+ir3jEz3E/S4gKKafIuyuNZ3IYvqQuA1ZqrTw9g7MhjNCXcRiu6iSN8PmOdFHAthWW5o25Dc0boiqAFbW4oAJwezWZCrUh5ScsBs8Ps4CH4SEU5j930vhPOL8PFsEPYBznHKrdVnnKaflyNR13pBV5ewMUPpyTpsRQPSrylIXD+fP5KhShzZoyqxVRUcxwxZFqTQtN2fGxJlj688vAWstegEb4xJvXOE0zHg3HNabLZMI/SkEkFUc+4GZ8d0jg7c3cJ0t/dSnNR+GmDsizDi9DK/BbLy1qObFQKvchnAoQFvx7SA+JvwPCEcP+AjgfSOLEolS7H3lHE0EsK31GhKfpuufmDsg4BtAlTXDp9D/h5xWhac/kZMmUnd8wJeMnTJIaGQTIdBpQX9B0XTWExVDIF2AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "StroopFrench",
          "title": "StroopFrench",
          "src": "/static/837c09313bb698533c6fb7ede6a72936/a6d36/StroopFrench.png",
          "srcSet": ["/static/837c09313bb698533c6fb7ede6a72936/222b7/StroopFrench.png 163w", "/static/837c09313bb698533c6fb7ede6a72936/ff46a/StroopFrench.png 325w", "/static/837c09313bb698533c6fb7ede6a72936/a6d36/StroopFrench.png 650w", "/static/837c09313bb698533c6fb7ede6a72936/e548f/StroopFrench.png 975w", "/static/837c09313bb698533c6fb7ede6a72936/3c492/StroopFrench.png 1300w", "/static/837c09313bb698533c6fb7ede6a72936/cf8e5/StroopFrench.png 1402w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <center>
      <br />
      <p><strong parentName="p">{`Remarquer que l'effet est également présent si les  mots sont dans une langue apprise (l'anglais), mais disparait quand les mots ou symboles ne sont plus reconnus par l'oeil en automatique`}</strong></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "512px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "77.91411042944786%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAAsSAAALEgHS3X78AAACWklEQVQoz11T31PaQBD2/3/r+FAs2kEBQaZahYCKqAWKKKBBSCR3t/crttJOA3WYcRICtj1i1cSdPFx297vd/b7bpWlgvudPxpOHyYPv+57nzefzv4Gpw3Tqqfj00fX//J4+LnKV5ylhiTHGGddxP1HI7LdOmRQEY2fkPIHv7+8xxozKoVF3UHzYbzBqE4LH43EA5kxQeXadL3benVyugWUQoI7zCiZEObCuZfXtrXZ+AzEEBP6DuSot2E2p2l4p6KsaXF0Txt6AEYUDO3v6c3VPrvfApIQ+V160zS5615XzWrl5NsAEgITBGCPBbUz0WvMISC84P7fNOQMQ9m3z8scyHn6SnGBCRpGZieDUOqh/+VDBBzUhufK8VOYq1mjR+H7r88kACCeAHWcUaRuh+kayk0wcx+OGYVIaaltKfnh8vrKqraeL5mBAAd4SBiTd2f7YziUustbAAggRRrkgZc1KxW6SK3DVIYxHwAhRKa2GhgoJ1Dxktq20fJ2ZgPhmVRwc+4VSAhsY6CgMxhgYr+T75UyrnDeoyickzDYvH+8nM2up7Ibe1dlbqdRtvE/OkZ02oK3YDT0SpmrTrmGmdrtHtS6liuzozFjpjHasnd1BbvMm04NeSOfFCxOnqJrob26aWTN4A1Gdseplq2S+37pKF/qU00jbQtrV8mEmtryXWscIQZRtpZNt29WCth1f+1osSSnxC2GCcyqkbDWHu5m7SlEsJqSjUUhnAKXI92LpLpe7K2hqylepvIW5nu+780d3Ng/+3Nls9rKSrut6wZaqTwWCuPu0kv8A865Dx4L6R9EAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "stroop",
            "title": "stroop",
            "src": "/static/57463e7f4cdbb6b158e1f319d1ab4c68/01e7c/stroop.png",
            "srcSet": ["/static/57463e7f4cdbb6b158e1f319d1ab4c68/222b7/stroop.png 163w", "/static/57463e7f4cdbb6b158e1f319d1ab4c68/ff46a/stroop.png 325w", "/static/57463e7f4cdbb6b158e1f319d1ab4c68/01e7c/stroop.png 512w"],
            "sizes": "(max-width: 512px) 100vw, 512px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span></p>
    </center>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "435px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "100%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAECAwX/xAAWAQEBAQAAAAAAAAAAAAAAAAABAgD/2gAMAwEAAhADEAAAAesiCtAzagOox//EABsQAQACAgMAAAAAAAAAAAAAAAECEQADEiIy/9oACAEBAAEFAu3K0dbcWxLZa/MiwgDn/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAEQIf/aAAgBAwEBPwEeX//EABcRAAMBAAAAAAAAAAAAAAAAAAACMRD/2gAIAQIBAT8BxYLD/8QAHxAAAgICAQUAAAAAAAAAAAAAAQIAIRFhEhBBcZGx/9oACAEBAAY/AmAYa1FUsM/ZZ5eIxCXitxSydvUscYRBrp//xAAZEAEAAwEBAAAAAAAAAAAAAAABABExIVH/2gAIAQEAAT8hVWlXEFgaZDcVexW06IpIvT68gThV4QGMYil8UTJ//9oADAMBAAIAAwAAABDwGID/xAAYEQEAAwEAAAAAAAAAAAAAAAABABARYf/aAAgBAwEBPxAHkGsv/8QAGhEAAwADAQAAAAAAAAAAAAAAAAEhETFRYf/aAAgBAgEBPxCt3QvCGx1kIf/EAB4QAQACAQQDAAAAAAAAAAAAAAEAESFBUWFxMbHR/9oACAEBAAE/ECWO8tq4zrHdErTKPKQq3xFsPqDu9CtniA4+fdQcSr3ht9y1ummmLXauXFZ+wwon/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "stroopChinese",
          "title": "stroopChinese",
          "src": "/static/56c4bb9141068bceac595e9edf0bc099/4a9ea/stroopChinese.jpg",
          "srcSet": ["/static/56c4bb9141068bceac595e9edf0bc099/d2f63/stroopChinese.jpg 163w", "/static/56c4bb9141068bceac595e9edf0bc099/c989d/stroopChinese.jpg 325w", "/static/56c4bb9141068bceac595e9edf0bc099/4a9ea/stroopChinese.jpg 435w"],
          "sizes": "(max-width: 435px) 100vw, 435px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <h2>{`Et tout le monde y passe`}</h2>
    <p>{`Les illusions qui font croire à nos yeux que des cercles fixes tournent sont nombreuses :`}</p>
    <iframe width="90%" height="800px" frameBorder="0" src="/gifs/rotsnake.jpg" />
    <p><a parentName="p" {...{
        "href": "http://www.ritsumei.ac.jp/~akitaoka/index-e.html"
      }}>{`D'après Akitaoka`}</a></p>
    <br />
    <p>{`Et ce ne sont pas seulement nos yeux. Il est certain que d'autres mammifères y sont aussi sensibles. `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=S4IHB3qK1KU"
      }}>{`Même les chats`}</a></p>
    <center>
      <iframe width="100%" height="500" src="/sandbox/catillusion.html" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen>
      </iframe>
    </center>
    <br />
    <Link to="/" mdxType="Link">Revenir à l'accueil</Link>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      